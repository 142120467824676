import React, { useEffect, useState, useCallback } from 'react'
import loadable from '@loadable/component'

import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@components/common/layout'
import '@styles/libya-art-piece.scss'

import { isMobile } from 'react-device-detect'
const Stories = loadable(() => import('react-insta-stories'))

const ArticleTags = () => {
  return (
    <div className={'gap-2 sm:block sm:px-0 inverted max-w-6xl flex flex-row justify-start items-start w-full'}>
      <a target='_blank' rel={'noopener noreferrer'} href='https://www.thenewhumanitarian.org/environnement-and-catastrophes' title='View more reports on Conflict' className='px-2 py-1 mr-4 font-bold text-zinc-50 bg-zinc-600 active:text-white hover:text-white focus:text-white' data-report-theme='Conflict'>
        Environment and Disasters
      </a>

      <a target='_blank' rel={'noopener noreferrer'} href='/special-report' title='View more articles of type Special Report' className='mr-4 font-bold text-zinc-50 hover:bg-transparent'>
        Special Report
      </a>

      <span className={'text-zinc-400'}>28 November 2023</span>
    </div>
  )
}

const Link = (props) => {
  return (
    <a
      target={props.target || '_blank'}
      href={props.href}
      className={`font-bold text-white hover:text-zinc-200 transition-colors duration-100 pointer-events-auto underline`}
    >
      {props.children}
    </a>
  )
}

const AuthorInfoOld = ({ name, role, imageUrl, twitter }) => {
  return (
    <div className={'px-0 sm:px-0 inverted max-w-6xl flex flex-row justify-start items-start w-full'}>
      <div
        className={'w-32 h-32 max-h-full mt-1 rounded-full flex-none'}
        style={{
          background: `url(${imageUrl})`,
          backgroundSize: 'cover',
        }}
      />
      <div className={'text-white ml-6'}>
        <div className={'flex flex-row justify-start items-start'}>
          <a target='_blank' rel='noopener noreferrer' href='https://www.thenewhumanitarian.org/authors/zainab-chamoun' target='_blank' className={'font-bold underline mb-2 focus:border-white active:text-white focus:text-white'}>
            <h3>{name}</h3>
          </a>
          <a target='_blank' rel='noopener noreferrer' href={`https://www.twitter.com/${twitter}`}>
            <span className={'pt-2 w-7 h-7 block'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" className={'inline-block ml-3 mr-4'}>
                <path d="M15.7512 1.3999H18.818L12.1179 8.94276L20 19.2069H13.8284L8.99458 12.9817L3.46359 19.2069H0.394938L7.5613 11.139L0 1.3999H6.32828L10.6976 7.08992L15.7512 1.3999ZM14.6748 17.3988H16.3742L5.4049 3.11303H3.58133L14.6748 17.3988Z" fill='white' />
              </svg>
            </span>
          </a>
        </div>
        <p>{role}</p>
      </div>
    </div>
  )
}

const LibyaArtPiece = (props) => {
  const [mobile, setIsMobile] = useState(isMobile)
  const [isPaused, setIsPaused] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(0)

  const Images = props.data.allFile.edges

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top - offset
    })
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowRight') {
        changeSlide('next');
      } else if (event.key === 'ArrowLeft') {
        changeSlide('prev');
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentSlide]); // Include currentSlide in the dependency array

  const changeSlide = (direction) => {
    scrollIntoViewWithOffset('#stories', isMobile ? 60 : 0)

    if (direction === 'next') {
      if (currentSlide + 1 >= StoriesArray.length) {
        setCurrentSlide(0)
      } else {
        setCurrentSlide(currentSlide + 1)
      }
    } else {
      if (currentSlide - 1 < 0) {
        setCurrentSlide(StoriesArray.length - 1)
      } else {
        setCurrentSlide(currentSlide - 1)
      }
    }
  }

  useEffect(() => {
    setIsMobile(isMobile)
    console.log('isMobile', isMobile)
  }, [isMobile])

  const getImageByName = useCallback(
    (name) => {
      return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
    },
    [Images]
  )


  const IMAGE_TITLES_DESKTOP = [
    '',
    '',
    '',
    'About the artist (1/2)',
    'About the artist (2/2)',
    'Valley Floods, 2023',
    'Valley Floods, 2023',
    'A hope for a new life, 2023',
    'Crying stars, 2023',
    'About the artist (1/2)',
    'About the artist (2/2)',
    'Derna, as I once knew it, 2023',
    'Derna, as I once knew it, 2023',
    'Derna, as I wept over it, 2023',
    'Derna, as I wept over it, 2023',
    'The Blue Zinco, 2023',
    'The Blue Zinco, 2023',
    'About the artist (1/3)',
    'About the artist (2/3)',
    'About the artist (3/3)',
    'Good Morning from Derna, 2023',
    'Good Morning from Derna, 2023',
    'Live from Derna, 2023',
    'Live from Derna, 2023',
    "Derna's smile is a homeland, 2023",
    "Derna's smile is a homeland, 2023",
    'Untitled, 2018',
  ]

  const IMAGE_TITLES_MOBILE = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'About the artist (1/4)',
    'About the artist (2/4)',
    'About the artist (3/4)',
    'About the artist (4/4)',
    'Valley Floods',
    'Valley Floods',
    'Valley Floods',
    'A hope for a new life',
    'A hope for a new life',
    'A hope for a new life',
    'Crying stars',
    'Crying stars',
    'About the artist (1/4)',
    'About the artist (2/4)',
    'About the artist (3/4)',
    'About the artist (4/4)',
    'Derna, as I once knew it',
    'Derna, as I once knew it',
    'Derna, as I once knew it',
    'Derna, as I once knew it',
    'Derna, as I wept over it',
    'Derna, as I wept over it',
    'Derna, as I wept over it',
    'The Blue Zinco',
    'The Blue Zinco',
    'The Blue Zinco',
    'About the artist (1/5)',
    'About the artist (2/5)',
    'About the artist (3/5)',
    'About the artist (4/5)',
    'About the artist (5/5)',
    'Good Morning from Derna',
    'Good Morning from Derna',
    'Good Morning from Derna',
    'Live from Derna',
    'Live from Derna',
    'Live from Derna',
    'Live from Derna',
    "Derna's smile",
    "Derna's smile",
    "Derna's smile",
    "Derna's smile",
    "",
  ]

  const SlideImageFull = (props) => {
    return (
      <div
        className={`${props.objectFit === 'contain' ? 'pb-0' : ''
          } w-full h-full absolute text-white flex items-center justify-center flex-col`}
        style={{
          backgroundColor: props.bgColor || 'transparent',
        }}
      >
        {props.imageName && (
          <GatsbyImage
            image={getImageByName(isMobile ? `mobile-${props.imageName}` : `desktop-${props.imageName}`)}
            className={`absolute top-0 left-0 w-full h-full min-h-full ${props.objectFit === 'contain' ? '-mt-[5.75rem]' : ''
              }`}
            imgClassname={'w-full max-w-full'}
            objectFit={props.objectFit || 'cover'}
            alt={''}
          />
        )}
        {props.caption &&
          <div className={'top-5 bg-white left-0 right-0 mx-5 absolute bg-opacity-80 hover:bg-opacity-100 px-3 py-1'}>
            <p>{props.caption}</p>
          </div>
        }
        <div className={'absolute top-0 left-0 w-full h-full z-50 flex items-center justify-center flex-col p-4'}>{props.children}</div>
      </div>
    )
  }

  const SlideImageLeft = (props) => {
    return (
      <div className={`relative w-full h-screen-mobile sm:h-screen-desktop flex flex-col sm:grid sm:grid-cols-2 responsive-font ${props.isArtistSlide ? 'bg-zinc-700' : ''}`}>
        <div className={'relative'}>
          <GatsbyImage
            image={getImageByName(isMobile ? `mobile-${props.imageName}` : `desktop-${props.imageName}`)}
            className={'relative top-0 left-0 right-0 h-1/2 sm:w-full sm:h-full'}
            imgClassname={'w-full max-w-full'}
            objectFit={props.objectFit || 'cover'}
            style={{
              height: props.objectFit !== 'cover' ? 'calc(100% - 60px)' : '100%',
            }}
            alt={''}
          />
          {props.caption &&
            <div className={'top-0 bg-white left-0 absolute bg-opacity-90 z-50 m-2 hover:bg-opacity-100 px-3 py-1'}>
              <p className={'text-2xl'}>{props.caption}</p>
            </div>
          }
        </div>
        <div className={'flex justify-center items-center flex-col w-full h-screen-mobile sm:h-screen-desktop'}>
          {props.children}
        </div>
      </div>
    )
  }

  const SlideImageRight = (props) => {
    return (
      <div className={`w-full h-screen-mobile sm:h-screen-desktop flex flex-col sm:grid sm:grid-cols-2 responsive-font ${props.isArtistSlide ? 'bg-zinc-700' : ''}`}>
        <div className={'flex justify-center items-center flex-col w-full h-screen-mobile sm:h-screen-desktop'}>
          {props.children}
        </div>
        <div className={'relative'}>
          <GatsbyImage
            image={getImageByName(isMobile ? `mobile-${props.imageName}` : `desktop-${props.imageName}`)}
            className={'relative top-0 left-0 h-1/2 sm:w-full sm:h-full'}
            imgClassname={'w-full max-w-full'}
            objectFit={props.objectFit || 'contain'}
            style={{
              height: props.objectFit !== 'cover' ? 'calc(100% - 60px)' : '100%',
            }}
            alt={''}
          />
          {props.caption &&
            <div className={'top-0 bg-white left-0 absolute bg-opacity-90 z-50 m-2 hover:bg-opacity-100 px-3 py-1 max-w-1/2'}>
              <p className={'text-2xl'}>{props.caption}</p>
            </div>
          }
        </div>
      </div>
    )
  }

  const InvertedParagraph = (props) => {
    return (
      <p className={`text-zinc-50 inline-block pointer-events-none ${props.ellipses ? 'm-0 font-bold text-3xl' : ''}`} style={{ zIndex: 9999 }}>
        {props.children}
      </p>
    )
  }

  // const InvertedParagraphSans = (props) => {
  //     return (
  //         <p className={`text-zinc-50 inline-block pointer-events-none ${props.ellipses ? 'm-0 font-bold font-sans text-3xl' : ''}`} style={{ zIndex: 9999 }}>
  //             {props.children}
  //         </p>
  //     )
  // }

  const InvertedTitle = (props) => {
    return (
      <h2 className={'text-zinc-50 inline-block pointer-events-none font-title'} style={{ zIndex: 9999 }}>
        {props.children}
      </h2>
    )
  }

  const InvertedTitleMain = (props) => {
    return (
      <h3 className={'text-zinc-50 inline-block pointer-events-none text-4xl sm:text-5xl font-title font-bold m-0'} style={{ zIndex: 9999 }}>
        {props.children}
      </h3>
    )
  }

  const InvertedTitleSans = (props) => {
    return (
      <h3 className={'text-zinc-50 inline-block pointer-events-none text-2xl sm:text-4xl font-sans font-bold m-0'} style={{ zIndex: 9999 }}>
        {props.children}
      </h3>
    )
  }

  const SlideContent = (props) => {
    return (
      <div
        className={`relative w-full m-0 mb-0 sm:mb-28 flex sm:items-start justify-center flex-col p-5 sm:p-8 flow ${props.className}`}
        style={{
          maxWidth: '100%',
          minHeight: '-webkit-fill-available',
          height: isMobile ? '100vh' : '100%',
          paddingBottom: isMobile ? '70px' : '2rem',
        }}
      >
        {props.children}
      </div>
    )
  }

  const StoriesMobile = [
    /* Intro slides */
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideImageFull imageName={'Derna-wept'}>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              // enter={{
              //   opacity: 1,
              // }}
              transition={{
                duration: 2,
                delay: 1,
              }}
              className={'w-full h-full text-white flex items-center justify-center flex-col z-50'}
            >
              <div className={'block px-4 p-2 bg-white -mt-10 sm:mt-5 text-black shadow-xl text-center'}>
                <h1 className={'mb-4 mt-2'}>Art in a time of crisis: Drawing Derna</h1>
                <p className={'leading-normal text-black'}>From Libya’s floods, artists show despair, remembrance, and hope.</p>
                <hr className={'mt-5 mb-3'} />
                <p className={'pt-2 opacity-90 text-center leading-normal mb-3'}>Use the <strong>← Back</strong> and <strong>Next →</strong> buttons to navigate through the story.</p>
              </div>
            </motion.div>
          </SlideImageFull>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedTitleSans>
              Two and a half months since massive floods hit northeast Libya, killing more than 4,000 people almost instantly and sweeping entire neighbourhoods into the water, many are still trying to reckon with what happened that day in September.
            </InvertedTitleSans>
            <br />
            <InvertedParagraph>That includes Libya’s artists, who have been translating their anguish into new works that show solidarity, grief, and anger. Others have found it difficult to work through a catastrophe that killed so many, so quickly. </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull caption={'Entire neighbourhoods were destroyed in the northeast Libyan city of Derna when two dams collapsed on 10-11 September. Esam Omran al-Fetori/Reuters'} imageName={'story-1'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>
              While the disaster has largely dropped from international headlines, superseded by events in Gaza, it has not been forgotten in Libya. <Link href="https://reliefweb.int/report/libya/iom-libya-resilience-recovery-reconstruction-plan-storm-daniel-september-2023-september-2024">Tens of thousands</Link> of people have been unable to return to their destroyed homes, more than 8,000 are still missing, and a recent <Link href="https://reliefweb.int/report/libya/libya-flood-response-humanitarian-update-14-november-2023-enar">diarrhoea outbreak</Link> in the city of al-Bayda is a reminder of the damage done to infrastructure, including critical water treatment and sewage facilities.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull caption={'The new landscape of Derna, weeks after floods tore through the city. Esam Omran al-Fetori/Reuters'} imageName={'story-2'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>
              But the damage is psychological, too; an unhealed wound. It has changed how many see their homes and themselves. Derna, a city once known for its jasmine flowers, thriving cultural scene, and its green valley, is now known as the place where the dams collapsed, taking homes and families with them.
            </InvertedParagraph>
            <InvertedParagraph>
              Art often expresses trauma (and can sometimes <Link href="https://interactive.thenewhumanitarian.org/stories/2021/12/8/drawing-syrias-trauma/">help process it</Link>) in a unique way, and so The New Humanitarian spoke with three Libyan artists — Nour al-Majiri of Derna, Hend Husain of al-Bayda, and Osama Belaed of Tripoli — about what the time since the floods has been like for them, and what they have been creating. Although they each experienced the disaster differently, they all expressed one shared emotion: pain.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>
              Read on to see their work, and to read their thoughts on art in a time of crisis.
            </InvertedParagraph>
            <InvertedParagraph>
              <em>(The artists’ words have been condensed and edited for clarity.)</em>
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    /* Nour al-Majiri, Derna */
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'intro-nour'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedTitleMain>Solace through art</InvertedTitleMain>
            <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
            <br />
            <InvertedParagraph>Al-Majiri, 28, a self-taught artist from the city of Derna, experienced the disaster first hand. </InvertedParagraph>
            <InvertedParagraph>She woke up on the morning of 11 September to news that her neighbours had been swept away by the floods, and the days that followed were filled with news of more loved ones who had died, including her uncle, his wife, and their four daughters.</InvertedParagraph>
            <InvertedParagraph>The sound of rain made her anxious, and without regular internet or phone service in the days after the disaster, art became a lifeline.</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>“When I started creating art, I felt that I am fine, or I will be fine… The fact that I have ideas and the dedication to produce art, it means that I will be okay,” she explains.</InvertedParagraph>
            <InvertedParagraph>But creating anything directly related to the floods has not been easy, and it sometimes felt like a burden to confront the catastrophe head on. “Life completely stopped,” she says. “It was heavy to think about the disaster with a full awareness of what happened.”</InvertedParagraph>
            <InvertedParagraph>For al-Majiri, sharing her artwork now is a way of reclaiming a pre-disaster reality that she loved, in a city that has been through so much: It was once occupied by the so-called Islamic State, but she says it is so much more than that, more than the floods, too.</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>She still sees Derna, or as Libyans call it, Derna al-Zahra (Blooming Derna, because of its famous jasmine flowers), as a vibrant town with a flourishing artistic scene – full of journalists, doctors, and regular people like her.</InvertedParagraph>
            <InvertedParagraph>“What happened is disastrous,” she says. “But maybe through the work of artists, the world will see Derna differently. Derna is a normal city with normal people who love life, art, and care about others.”</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'Valley-Floods'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Valley Floods, 2023</InvertedTitleMain>
            <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
            <br />
            <InvertedParagraph>
              “This collage was actually in the making two months before the disaster. I stumbled upon some pictures of ships in an old book, and something within me said to cut them out and save them for a future artwork. I didn’t have a specific plan for them at the time, I just knew I would use them when the right moment came.
            </InvertedParagraph>
            <InvertedParagraph>
              A few days after the disaster, burdened with news of who had died and who had survived, I turned to an old textbook. I didn’t feel like drawing anything from scratch. Instead, I took refuge in cutting, pasting, and creating collages. The process of touching papers, feeling textures, and cutting from here and there allowed me a sense of freedom and disconnection from reality. It was mainly a way to release my emotions and negative energy.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              I flipped through the pages of this old English book in search of a text that spoke to what I was feeling. It felt like the text I found was about our own disaster; I could see Derna in it. Muddy water, flood water, the dam and its gates, and the river – all the triggering words were there. I also cut a few images of birds from the same book.
            </InvertedParagraph>
            <InvertedParagraph>
              In the days right after the floods, I couldn’t create anything about the disaster. But I completely immersed myself in my work, disconnecting from the outside world. I wanted an escape through my art, an escape from the new news filled with heartbreaking stories of neighbours swept away by the flood.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'A-hope-for-a-new-life'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>A hope for a new life, 2023</InvertedTitleMain>
            <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
            <br />
            <InvertedParagraph>
              “This is a form of personal art therapy that I have been practising recently. It’s the idea of taking a completed written literary work and turning it into a story of your own… It calms me down.
            </InvertedParagraph>
            <InvertedParagraph>
              I looked through the words in this book, hoping to create a different story, find life. I was trying to save what was left of my soul, and the story of Derna, my city.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              I created this piece in a state of loss and distraction. My brain was exhausted from the toll of the news and the scale of the disaster, but I insisted on working on this as an act of refusal against the tragedy and all the death, and as an attempt to start a new story with a new meaning.
            </InvertedParagraph>
            <InvertedParagraph>
              This piece reflects my state then, somewhere between life and death. I was able to survive and see the light of a new life and world. Through the moon, I see a reflection of a new life and wonder what I will do with the chance I have been given to live.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'Crying-stars'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Crying stars, 2023</InvertedTitleMain>
            <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
            <br />
            <InvertedParagraph>
              “I used this piece to connect with the pre-disaster life I knew and loved. I mostly draw and paint women. I used watercolours because I like how smooth they are. I wanted to use painting to shift my mood, so I included embroidery too, to depict the stars on the disastrous night, and tears. I didn’t want to make something complex, I just wanted to let my emotions flow.
            </InvertedParagraph>
            <InvertedParagraph>
              Making this was calming and refreshing. It genuinely made me happy. Shades of blue and black are dominant. Looking at it now, the colours make me wonder if my subconscious was affected by the events.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    /* Hend Husain (“Atouqah”) */
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'intro-hend'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedTitleMain>Preserving the memory of Derna’s green valley</InvertedTitleMain>
            <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
            <br />
            <InvertedParagraph>Husain, from the nearby eastern city of al-Bayda, has long felt connected to Derna. She describes it as the soul of Libya, a place where people go for escape and healing.</InvertedParagraph>
            <InvertedParagraph>In 2014, an artist from Derna helped her pursue her longtime passion of painting as a full-time profession. “He gave me canvases, brushes, and oil points, and encouraged me to go ahead,” she says. She doesn’t know if he survived the floods.</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>She signs her art “Atouqah”, which means “chicken” in Arabic. When she was a child, other kids called her this name to bully her. Now, she has embraced it as her artistic identity, as she focuses on the challenges she says women face in Libya and other conservative Muslim societies.</InvertedParagraph>
            <InvertedParagraph>While al-Bayda was hit by the floods, it wasn’t nearly as bad as in Derna. As the scale of the disaster unfolded, Husain sought refuge in art. </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>From the confines of her room, she translated painful thoughts and emotions into vivid oil paintings. Husain’s art shows Derna’s valley, both before and after the catastrophe. Although life is starting to get back to normal in al-Bayda, she says it will always be different. She poses this question: “How did the water, meant to sustain life, kill the life within the valley and its people?”</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'Derna-As-I-Knew-It'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Derna, as I once knew it, 2023</InvertedTitleMain>
            <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
            <br />
            <InvertedParagraph>
              “This artwork draws inspiration from different sources: A photograph of myself by the valley of Derna from September 2020, and a picture published on social media of a man I call the ‘great Derna survivor’.
            </InvertedParagraph>
            <InvertedParagraph>
              Footage of the disaster was all over social media, but the photo of this man struck me. Standing alone, walking around, his hands folded behind his back, I wondered what he was thinking. Maybe it was: ‘I have got nothing to do, I have nothing left’. I could imagine him longing for his lost home, family, and city.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              He is a survivor, but he carries a heavy burden, like every Libyan citizen. He represents all of us. I saved his photo to sketch it later, with him looking out on the green valley as we knew it before the disaster. I drew myself next to him, contemplating my life.
            </InvertedParagraph>
            <InvertedParagraph>
              The photo of me that I drew was taken on a trip that is very special to me. It was a healing trip. I cried as I revisited this photograph and drew myself.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              I mainly created this piece to document the Derna of the past, before the disaster, and to preserve its beauty in our collective memory. It is evidence that the Derna valley, with its natural beauty and resources, once thrived. It proves that while future cities might be built in the same place, over the bodies of the dead, Derna once existed with gems that only the people of the valley knew about. The disaster happened and I witnessed it.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'Derna-wept'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Derna, as I wept over it, 2023</InvertedTitleMain>
            <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
            <br />
            <InvertedParagraph>
              “I painted this while imagining what it would have felt to stand near the valley, hearing the cries of people, begging the water to stop. I saw the victims and survivors as if I was right there with them. I made this because words failed me.
            </InvertedParagraph>
            <InvertedParagraph>
              This was painful to paint. I couldn’t bring myself to paint people dying, so instead showed their hands emerging from water. The brownish colour of the water mixed with mud didn’t have much significance before the disaster, but now I try to avoid it in my clothes, in my room, in my life. But I couldn’t avoid it in this painting. It is the colour of the storm.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              I painted this with deep empathy for the experiences of the people of the valley. Perhaps some of them were still asleep when the water swept over them. Some people woke up to the water and couldn’t breathe anymore. Others left their houses in search of safety, only to be swept away by the flood. They couldn’t save themselves. Some people survived. I thought about all of it. It was so painful.
            </InvertedParagraph>
            <InvertedParagraph>
              This painting is the ‘after’ version of the previous painting. It stands as evidence of what was done to the valley. Evidence that will live forever. It is not only an expression of grief, but also of rage.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'blue-zinco'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>The Blue Zinco, 2023</InvertedTitleMain>
            <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
            <br />
            <InvertedParagraph>
              This is called “The Blue Corrugated Tin”, or as we refer to it in Libyan dialect, “The Blue Zinco”. Across Libya, when blue zinco goes up, it means to us that the [area is going to be demolished], and the local community will suffer. Owners of damaged places and properties will be ripped off by whoever controls the area.
            </InvertedParagraph>
            <InvertedParagraph>
              Two weeks after the disaster, blue zinco arrived in Derna. At that moment, we knew that Derna would never be the same again. A new system will control and rule it, even against the will of Derna’s people.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              The blue sky embracing fluffy clouds is an attempt to show life returning to the city. But the authorities, embodied by the blue zinco, are a barrier between the great survivor of Derna, and a normal life.
            </InvertedParagraph>
            <InvertedParagraph>
              He is standing on muddy ground, in an ongoing struggle to overcome what happened the day of the disaster. But it continues to repeat in his mind. He stands still in that very moment, experiencing the same emotions, again and again.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    /* Osama Belaed, Tripoli  */
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'intro-osama'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedTitleMain>Keeping the spotlight on Derna</InvertedTitleMain>
            <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
            <br />
            <InvertedParagraph>Osama Belaed, 45, first began creating art while pursuing a master’s degree in digital advertising in the UK, back in 2017.</InvertedParagraph>
            <InvertedParagraph>Now living back in the Libyan capital, Tripoli – his home city – Belaed spent hours desperately trying to reach close friends in Derna after waking up the morning after the disaster to hear the devastating news.</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>He reached most of them — one had lost 90 members of his extended family — but couldn’t track down his best friend, Gaith. They had had a silly argument just before the floods. For five days, Belaed thought that was to be their last conversation.  </InvertedParagraph>
            <InvertedParagraph>Then, at 2am, Gaith called. “We cried a lot. He was crying. I was crying. My wife was crying. He told me about the horrific experience they had been through. They nearly drowned.”</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>Belaed began making a piece of digital art every day and sharing it on social media. He describes it as a form of therapy; a way to process the tragedy internally. It was also a way to remind the world of what happened in Derna, every single day. “Derna is not just something that was trending [on social media],” he says. “I want to remind everyone that it’s our responsibility to rebuild our beloved city and stand in solidarity with our brothers and sisters from Derna.”</InvertedParagraph>
            <InvertedParagraph>Despite never having visited Derna, Belaed feels an attachment through his friends. And through his art, he is fighting to keep Derna in the spotlight.</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent>
            <InvertedParagraph>He shares aspects of Derna that don’t come through in the standard media coverage of the disaster, like its reputation for jasmine flowers. </InvertedParagraph>
            <InvertedParagraph>Belaed hopes his art brings those who see it what they need, whatever that might be: “Each individual experiences the pain differently. If my artwork makes one person happy, then my job is done.”</InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'Good-Morning-from-Derna'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Good Morning from Derna, 2023</InvertedTitleMain>
            <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
            <br />
            <InvertedParagraph>
              “This piece shows an old man sitting, covered in mud, and holding a stick. He was on Al Jazeera. He lost almost all of his family: his daughters and his sons. He had been looking for them, and then he just gave in and started crying. The only things he could save from his house were a few books in a suitcase.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              You can see a suitcase, a broken, neglected suitcase. I added books and a few jasmine flowers behind them, and an old stamp from Derna. I also added a jasmine flower in his hand, just to show hope despite the painful situation.
            </InvertedParagraph>
            <InvertedParagraph>
              I added the water bottles near his feet because there were a lot of calls on Facebook and Instagram to donate bottled water to send to Derna – because they didn’t have safe water. It shows how Libyan people helped each other with whatever they had, and every little bit helps.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'Live-from-Derna'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Live from Derna, 2023</InvertedTitleMain>
            <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
            <br />
            <InvertedParagraph>
              “I think this is the most popular artwork that I have done. So many people shared this, in Libya and abroad.
            </InvertedParagraph>
            <InvertedParagraph>
              It shows  how much international media coverage there was about what happened in Derna. TV channels just flooded the country and went straight there.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              The headline is something I saw on Twitter (now known as X), in Arabic: ‘Right now, Derna is like a funeral home. But after the funeral, everyone goes back home, and only the afflicted families of Derna will remain, alone together.’
            </InvertedParagraph>
            <InvertedParagraph>
              It’s after the funeral that the painful memories start to sink in.
            </InvertedParagraph>
            <InvertedParagraph>
              The street sign reflects the feeling of loss and displacement that the people of Derna feel. No matter what the direction is, you can only go in the direction of ‘lost’ or ‘very lost’.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              The hand within the flood depicts the victims. There's also this man on crutches, who was photographed in Derna. He symbolises me. I feel broken, watching.
            </InvertedParagraph>
            <InvertedParagraph>
              In addition to images of destroyed buildings, there’s a sign ‘Welcome to Derna’, and a ‘No bikes’ sign. These are reminders of Derna before the disaster. Derna has a lot of narrow alleyways, which makes it hard for the cyclists to navigate.
            </InvertedParagraph>
            <InvertedParagraph>
              I created this to say that the disaster is way bigger than what we can imagine, and way bigger than what the media is showing.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return <SlideImageFull imageName={'Derna_s-smile-is-a-homeland'} objectFit={'contain'} />
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Derna’s smile is a homeland, 2023</InvertedTitleMain>
            <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
            <br />
            <InvertedParagraph>
              “This is a very painful piece. It includes several references to Derna in the past, present, and future.
            </InvertedParagraph>
            <InvertedParagraph>
              There’s a very old stamp from Derna, I believe it's Italian [from when Libya was an Italian colony]. And the photo of the old man with the red hat: This man is one of the missing people. He is a very well-known figure in Derna, famous and beloved by the city’s people.
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              He used to have a pond with so many ducks. But he’s lost.There’s also an ancient building that was destroyed.
            </InvertedParagraph>
            <InvertedParagraph>
              I added a vintage picture of two Libyan men: one is buying stuff from the other, and the flowers of jasmine are there too. Derna is also known for its apple orchards, so I included a basket of apples on an old chair. There’s a note in Arabic that says: ‘Here is love and here is Derna’. It’s a reminder that Derna’s disaster is the reason Libyans united [to bring aid across political divides].
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedParagraph>
              Another heartfelt message in Arabic says: ‘Derna’s smile is a homeland’. I’m trying to lift up the spirits of so many Libyans here, as a reminder that Derna is not dead. We lost loved ones, but Derna is still there. It’s up to us now to rebuild the city.
            </InvertedParagraph>
            <InvertedParagraph>
              The street sign, ‘New Life’, is a message of hope I’m trying to send out to myself and others. Even though the disaster is greater and way bigger than us, that does not mean that we cannot start again. We will start again; we will bring back Derna.”
            </InvertedParagraph>
          </SlideContent>
        )
      },
    },
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideContent className={'font-mobile'}>
            <InvertedTitleMain>Edited by Annie Slemrod.</InvertedTitleMain>
            <InvertedTitle>Click "Restart" to start again.</InvertedTitle>
          </SlideContent>
        )
      },
    },
  ]

  const StoriesDesktop = [
    /* Intro slides */
    {
      content: (storyProps) => {
        storyProps.action('pause')
        return (
          <SlideImageFull imageName={'Derna-wept'}>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              // enter={{
              //   opacity: 1,
              // }}
              transition={{
                duration: 3,
                delay: 0.25,
              }}
              className={'absolute top-0 left-0 w-full h-full text-white flex items-center justify-center flex-col z-50'}
            >
              <div className={'block px-12 py-7 bg-white text-zinc-900 shadow-xl mb-48 text-center'}>
                <h1 className={'mb-4'}>Art in a time of crisis: Drawing Derna</h1>
                <p className={'font-title-regular'}>From Libya’s floods, artists show despair, remembrance, and hope.</p>
                <hr className={'my-3'} />
                <p className={'pt-5 opacity-70 text-center'}>Use the <strong>← Back</strong> and <strong>Next →</strong> buttons to navigate through the story.</p>
              </div>
            </motion.div>
          </SlideImageFull>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'story-1'} objectFit={'cover'} caption={'Entire neighbourhoods were destroyed in the northeast Libyan city of Derna when two dams collapsed on 10-11 September. Esam Omran al-Fetori/Reuters'}>
            <SlideContent>
              {/* <InvertedTitleMain>Solace through art</InvertedTitleMain> */}
              <InvertedTitleSans>Two and a half months since massive floods hit northeast Libya, killing more than 4,000 people almost instantly and sweeping entire neighbourhoods into the water, many are still trying to reckon with what happened that day in September. </InvertedTitleSans>
              <br />
              <InvertedParagraph>
                That includes Libya’s artists, who have been translating their anguish into new works that show solidarity, grief, and anger. Others have found it difficult to work through a catastrophe that killed so many, so quickly.
              </InvertedParagraph>
              <InvertedParagraph>
                While the disaster has largely dropped from international headlines, superseded by events in Gaza, it has not been forgotten in Libya. <Link href="https://reliefweb.int/report/libya/iom-libya-resilience-recovery-reconstruction-plan-storm-daniel-september-2023-september-2024">Tens of thousands</Link> of people have been unable to return to their destroyed homes, more than 8,000 are still missing, and a recent <Link href="https://reliefweb.int/report/libya/libya-flood-response-humanitarian-update-14-november-2023-enar">diarrhoea outbreak</Link> in the city of al-Bayda is a reminder of the damage done to infrastructure, including critical water treatment and sewage facilities.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'story-2'} objectFit={'cover'} caption={'The new landscape of Derna, weeks after floods tore through the city. Esam Omran al-Fetori/Reuters'}>
            <SlideContent>
              <InvertedParagraph>
                But the damage is psychological, too; an unhealed wound. It has changed how many see their homes and themselves. Derna, a city once known for its jasmine flowers, thriving cultural scene, and its green valley, is now known as the place where the dams collapsed, taking homes and families with them.
              </InvertedParagraph>
              <InvertedParagraph>
                Art often expresses trauma (and can sometimes <Link href="https://interactive.thenewhumanitarian.org/stories/2021/12/8/drawing-syrias-trauma/">help process it</Link>) in a unique way, and so The New Humanitarian spoke with three Libyan artists — Nour al-Majiri of Derna, Hend Husain of al-Bayda, and Osama Belaed of Tripoli — about what the time since the floods has been like for them, and what they have been creating. Although they each experienced the disaster differently, they all expressed one shared emotion: pain.
              </InvertedParagraph>
              <InvertedParagraph>
                Read on to see their work, and to read their thoughts on art in a time of crisis.
              </InvertedParagraph>
              <InvertedParagraph>
                <em>(The artists’ words have been condensed and edited for clarity.)</em>
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft >
        )
      },
    },
    /* Nour al-Majiri, Derna */
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'intro-nour'} objectFit={'contain'} isArtistSlide>
            <SlideContent>
              <InvertedTitleMain>Solace through art</InvertedTitleMain>
              <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
              <br />
              <InvertedParagraph>Al-Majiri, 28, a self-taught artist from the city of Derna, experienced the disaster first hand. </InvertedParagraph>
              <InvertedParagraph>She woke up on the morning of 11 September to news that her neighbours had been swept away by the floods, and the days that followed were filled with news of more loved ones who had died, including her uncle, his wife, and their four daughters.</InvertedParagraph>
              <InvertedParagraph>The sound of rain made her anxious, and without regular internet or phone service in the days after the disaster, art became a lifeline.</InvertedParagraph>
              <InvertedParagraph>“When I started creating art, I felt that I am fine, or I will be fine… The fact that I have ideas and the dedication to produce art, it means that I will be okay,” she explains.</InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'intro-nour'} objectFit={'contain'} isArtistSlide>
            <SlideContent>
              <InvertedParagraph>But creating anything directly related to the floods has not been easy, and it sometimes felt like a burden to confront the catastrophe head on. “Life completely stopped,” she says. “It was heavy to think about the disaster with a full awareness of what happened.”</InvertedParagraph>
              <InvertedParagraph>For al-Majiri, sharing her artwork now is a way of reclaiming a pre-disaster reality that she loved, in a city that has been through so much: It was once occupied by the so-called Islamic State, but she says it is so much more than that, more than the floods, too.</InvertedParagraph>
              <InvertedParagraph>She still sees Derna, or as Libyans call it, Derna al-Zahra (Blooming Derna, because of its famous jasmine flowers), as a vibrant town with a flourishing artistic scene – full of journalists, doctors, and regular people like her.</InvertedParagraph>
              <InvertedParagraph>“What happened is disastrous,” she says. “But maybe through the work of artists, the world will see Derna differently. Derna is a normal city with normal people who love life, art, and care about others.”</InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'Valley-Floods'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>Valley Floods, 2023</InvertedTitleMain>
              <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
              <br />
              <InvertedParagraph>
                “This collage was actually in the making two months before the disaster. I stumbled upon some pictures of ships in an old book, and something within me said to cut them out and save them for a future artwork. I didn’t have a specific plan for them at the time, I just knew I would use them when the right moment came.
              </InvertedParagraph>
              <InvertedParagraph>
                A few days after the disaster, burdened with news of who had died and who had survived, I turned to an old textbook. I didn’t feel like drawing anything from scratch. Instead, I took refuge in cutting, pasting, and creating collages. The process of touching papers, feeling textures, and cutting from here and there allowed me a sense of freedom and disconnection from reality. It was mainly a way to release my emotions and negative energy.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'Valley-Floods'} objectFit={'contain'}>
            <SlideContent>
              <InvertedParagraph>
                I flipped through the pages of this old English book in search of a text that spoke to what I was feeling. It felt like the text I found was about our own disaster; I could see Derna in it. Muddy water, flood water, the dam and its gates, and the river – all the triggering words were there. I also cut a few images of birds from the same book.
              </InvertedParagraph>
              <InvertedParagraph>
                In the days right after the floods, I couldn’t create anything about the disaster. But I completely immersed myself in my work, disconnecting from the outside world. I wanted an escape through my art, an escape from the new news filled with heartbreaking stories of neighbours swept away by the flood.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'A-hope-for-a-new-life'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>A hope for a new life, 2023</InvertedTitleMain>
              <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
              <br />
              <InvertedParagraph>
                “This is a form of personal art therapy that I have been practising recently. It’s the idea of taking a completed written literary work and turning it into a story of your own… It calms me down.
              </InvertedParagraph>
              <InvertedParagraph>
                I looked through the words in this book, hoping to create a different story, find life. I was trying to save what was left of my soul, and the story of Derna, my city.
              </InvertedParagraph>
              <InvertedParagraph>
                I created this piece in a state of loss and distraction. My brain was exhausted from the toll of the news and the scale of the disaster, but I insisted on working on this as an act of refusal against the tragedy and all the death, and as an attempt to start a new story with a new meaning.
              </InvertedParagraph>
              <InvertedParagraph>
                This piece reflects my state then, somewhere between life and death. I was able to survive and see the light of a new life and world. Through the moon, I see a reflection of a new life and wonder what I will do with the chance I have been given to live.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'Crying-stars'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>Crying stars, 2023</InvertedTitleMain>
              <InvertedTitle>Nour al-Majiri, Derna</InvertedTitle>
              <br />
              <InvertedParagraph>
                “I used this piece to connect with the pre-disaster life I knew and loved. I mostly draw and paint women. I used watercolours because I like how smooth they are. I wanted to use painting to shift my mood, so I included embroidery too, to depict the stars on the disastrous night, and tears. I didn’t want to make something complex, I just wanted to let my emotions flow.
              </InvertedParagraph>
              <InvertedParagraph>
                Making this was calming and refreshing. It genuinely made me happy. Shades of blue and black are dominant. Looking at it now, the colours make me wonder if my subconscious was affected by the events.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    /* Hend Husain (“Atouqah”) */
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'intro-hend'} objectFit={'contain'} isArtistSlide>
            <SlideContent>
              <InvertedTitleMain>Preserving the memory of Derna’s green valley</InvertedTitleMain>
              <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
              <br />
              <InvertedParagraph>Husain, from the nearby eastern city of al-Bayda, has long felt connected to Derna. She describes it as the soul of Libya, a place where people go for escape and healing.</InvertedParagraph>
              <InvertedParagraph>In 2014, an artist from Derna helped her pursue her longtime passion of painting as a full-time profession. “He gave me canvases, brushes, and oil points, and encouraged me to go ahead,” she says. She doesn’t know if he survived the floods.</InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'intro-hend'} objectFit={'contain'} isArtistSlide>
            <SlideContent>
              <InvertedParagraph>She signs her art “Atouqah”, which means “chicken” in Arabic. When she was a child, other kids called her this name to bully her. Now, she has embraced it as her artistic identity, as she focuses on the challenges she says women face in Libya and other conservative Muslim societies.</InvertedParagraph>
              <InvertedParagraph>While al-Bayda was hit by the floods, it wasn’t nearly as bad as in Derna. As the scale of the disaster unfolded, Husain sought refuge in art. </InvertedParagraph>
              <InvertedParagraph>From the confines of her room, she translated painful thoughts and emotions into vivid oil paintings. Husain’s art shows Derna’s valley, both before and after the catastrophe. Although life is starting to get back to normal in al-Bayda, she says it will always be different. She poses this question: “How did the water, meant to sustain life, kill the life within the valley and its people?”</InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'Derna-As-I-Knew-It'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>Derna, as I once knew it, 2023</InvertedTitleMain>
              <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
              <br />
              <InvertedParagraph>
                “This artwork draws inspiration from different sources: A photograph of myself by the valley of Derna from September 2020, and a picture published on social media of a man I call the ‘great Derna survivor’.
              </InvertedParagraph>
              <InvertedParagraph>
                Footage of the disaster was all over social media, but the photo of this man struck me. Standing alone, walking around, his hands folded behind his back, I wondered what he was thinking. Maybe it was: ‘I have got nothing to do, I have nothing left’. I could imagine him longing for his lost home, family, and city.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'Derna-As-I-Knew-It'} objectFit={'contain'}>
            <SlideContent>
              <InvertedParagraph>
                He is a survivor, but he carries a heavy burden, like every Libyan citizen. He represents all of us. I saved his photo to sketch it later, with him looking out on the green valley as we knew it before the disaster. I drew myself next to him, contemplating my life.
              </InvertedParagraph>
              <InvertedParagraph>
                The photo of me that I drew was taken on a trip that is very special to me. It was a healing trip. I cried as I revisited this photograph and drew myself.
              </InvertedParagraph>
              <InvertedParagraph>
                I mainly created this piece to document the Derna of the past, before the disaster, and to preserve its beauty in our collective memory. It is evidence that the Derna valley, with its natural beauty and resources, once thrived. It proves that while future cities might be built in the same place, over the bodies of the dead, Derna once existed with gems that only the people of the valley knew about. The disaster happened and I witnessed it.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'Derna-wept'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>Derna, as I wept over it, 2023</InvertedTitleMain>
              <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
              <br />
              <InvertedParagraph>
                “I painted this while imagining what it would have felt to stand near the valley, hearing the cries of people, begging the water to stop. I saw the victims and survivors as if I was right there with them. I made this because words failed me.
              </InvertedParagraph>
              <InvertedParagraph>
                This was painful to paint. I couldn’t bring myself to paint people dying, so instead showed their hands emerging from water. The brownish colour of the water mixed with mud didn’t have much significance before the disaster, but now I try to avoid it in my clothes, in my room, in my life. But I couldn’t avoid it in this painting. It is the colour of the storm.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'Derna-wept'} objectFit={'contain'}>
            <SlideContent>
              <InvertedParagraph>
                I painted this with deep empathy for the experiences of the people of the valley. Perhaps some of them were still asleep when the water swept over them. Some people woke up to the water and couldn’t breathe anymore. Others left their houses in search of safety, only to be swept away by the flood. They couldn’t save themselves. Some people survived. I thought about all of it. It was so painful.
              </InvertedParagraph>
              <InvertedParagraph>
                This painting is the ‘after’ version of the previous painting. It stands as evidence of what was done to the valley. Evidence that will live forever. It is not only an expression of grief, but also of rage.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'blue-zinco'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>The Blue Zinco, 2023</InvertedTitleMain>
              <InvertedTitle>Hend Husain (“Atouqah”)</InvertedTitle>
              <br />
              <InvertedParagraph>
                This is called “The Blue Corrugated Tin”, or as we refer to it in Libyan dialect, “The Blue Zinco”. Across Libya, when blue zinco goes up, it means to us that the [area is going to be demolished], and the local community will suffer. Owners of damaged places and properties will be ripped off by whoever controls the area.
              </InvertedParagraph>
              <InvertedParagraph>
                Two weeks after the disaster, blue zinco arrived in Derna. At that moment, we knew that Derna would never be the same again. A new system will control and rule it, even against the will of Derna’s people.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'blue-zinco'} objectFit={'contain'}>
            <SlideContent>
              <InvertedParagraph>
                The blue sky embracing fluffy clouds is an attempt to show life returning to the city. But the authorities, embodied by the blue zinco, are a barrier between the great survivor of Derna, and a normal life.
              </InvertedParagraph>
              <InvertedParagraph>
                He is standing on muddy ground, in an ongoing struggle to overcome what happened the day of the disaster. But it continues to repeat in his mind. He stands still in that very moment, experiencing the same emotions, again and again.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    /* Osama Belaed, Tripoli  */
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'intro-osama'} objectFit={'contain'} isArtistSlide>
            <SlideContent>
              <InvertedTitleMain>Keeping the spotlight on Derna</InvertedTitleMain>
              <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
              <br />
              <InvertedParagraph>Osama Belaed, 45, first began creating art while pursuing a master’s degree in digital advertising in the UK, back in 2017.</InvertedParagraph>
              <InvertedParagraph>Now living back in the Libyan capital, Tripoli – his home city – Belaed spent hours desperately trying to reach close friends in Derna after waking up the morning after the disaster to hear the devastating news.</InvertedParagraph>
              <InvertedParagraph>He reached most of them — one had lost 90 members of his extended family — but couldn’t track down his best friend, Gaith. They had had a silly argument just before the floods. For five days, Belaed thought that was to be their last conversation.  </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'intro-osama'} objectFit={'contain'} isArtistSlide>
            <SlideContent>
              <InvertedParagraph>Then, at 2am, Gaith called. “We cried a lot. He was crying. I was crying. My wife was crying. He told me about the horrific experience they had been through. They nearly drowned.”</InvertedParagraph>
              <InvertedParagraph>Belaed began making a piece of digital art every day and sharing it on social media. He describes it as a form of therapy; a way to process the tragedy internally. It was also a way to remind the world of what happened in Derna, every single day. “Derna is not just something that was trending [on social media],” he says. “I want to remind everyone that it’s our responsibility to rebuild our beloved city and stand in solidarity with our brothers and sisters from Derna.”</InvertedParagraph>
              <InvertedParagraph>Despite never having visited Derna, Belaed feels an attachment through his friends. And through his art, he is fighting to keep Derna in the spotlight.</InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'intro-osama'} objectFit={'contain'} isArtistSlide>
            <SlideContent>
              <InvertedParagraph>He shares aspects of Derna that don’t come through in the standard media coverage of the disaster, like its reputation for jasmine flowers. </InvertedParagraph>
              <InvertedParagraph>Belaed hopes his art brings those who see it what they need, whatever that might be: “Each individual experiences the pain differently. If my artwork makes one person happy, then my job is done.”</InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'Good-Morning-from-Derna'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>Good Morning from Derna, 2023</InvertedTitleMain>
              <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
              <br />
              <InvertedParagraph>
                “This piece shows an old man sitting, covered in mud, and holding a stick. He was on Al Jazeera. He lost almost all of his family: his daughters and his sons. He had been looking for them, and then he just gave in and started crying. The only things he could save from his house were a few books in a suitcase.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageRight imageName={'Good-Morning-from-Derna'} objectFit={'contain'}>
            <SlideContent>
              <InvertedParagraph>
                You can see a suitcase, a broken, neglected suitcase. I added books and a few jasmine flowers behind them, and an old stamp from Derna. I also added a jasmine flower in his hand, just to show hope despite the painful situation.
              </InvertedParagraph>
              <InvertedParagraph>
                I added the water bottles near his feet because there were a lot of calls on Facebook and Instagram to donate bottled water to send to Derna – because they didn’t have safe water. It shows how Libyan people helped each other with whatever they had, and every little bit helps.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'Live-from-Derna'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>Live from Derna, 2023</InvertedTitleMain>
              <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
              <br />
              <InvertedParagraph>
                “I think this is the most popular artwork that I have done. So many people shared this, in Libya and abroad.
              </InvertedParagraph>
              <InvertedParagraph>
                It shows  how much international media coverage there was about what happened in Derna. TV channels just flooded the country and went straight there.
              </InvertedParagraph>
              <InvertedParagraph>
                The headline is something I saw on Twitter (now known as X), in Arabic: ‘Right now, Derna is like a funeral home. But after the funeral, everyone goes back home, and only the afflicted families of Derna will remain, alone together.’
              </InvertedParagraph>
              <InvertedParagraph>
                It’s after the funeral that the painful memories start to sink in.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          // <SlideImageRight imageName={'Live-from-Derna'} objectFit={'cover'}>
          <SlideImageRight imageName={'Live-from-Derna'} objectFit={'contain'}>
            <SlideContent>
              {/* <InvertedTitle>Live from Derna, 2023</InvertedTitle>
                            <InvertedParagraph>Osama Belaed, Tripoli</InvertedParagraph>
                            <br /> */}
              <InvertedParagraph>
                The street sign reflects the feeling of loss and displacement that the people of Derna feel. No matter what the direction is, you can only go in the direction of ‘lost’ or ‘very lost’.
              </InvertedParagraph>
              <InvertedParagraph>
                The hand within the flood depicts the victims. There's also this man on crutches, who was photographed in Derna. He symbolises me. I feel broken, watching.
              </InvertedParagraph>
              <InvertedParagraph>
                In addition to images of destroyed buildings, there’s a sign ‘Welcome to Derna’, and a ‘No bikes’ sign. These are reminders of Derna before the disaster. Derna has a lot of narrow alleyways, which makes it hard for the cyclists to navigate.
              </InvertedParagraph>
              <InvertedParagraph>
                I created this to say that the disaster is way bigger than what we can imagine, and way bigger than what the media is showing.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          <SlideImageLeft imageName={'Derna_s-smile-is-a-homeland'} objectFit={'contain'}>
            <SlideContent>
              <InvertedTitleMain>Derna’s smile is a homeland, 2023</InvertedTitleMain>
              <InvertedTitle>Osama Belaed, Tripoli</InvertedTitle>
              <br />
              <InvertedParagraph>
                “This is a very painful piece. It includes several references to Derna in the past, present, and future.
              </InvertedParagraph>
              <InvertedParagraph>
                There’s a very old stamp from Derna, I believe it's Italian [from when Libya was an Italian colony]. And the photo of the old man with the red hat: This man is one of the missing people. He is a very well-known figure in Derna, famous and beloved by the city’s people.
              </InvertedParagraph>
              <InvertedParagraph>
                He used to have a pond with so many ducks. But he’s lost.There’s also an ancient building that was destroyed.
              </InvertedParagraph>
            </SlideContent>
          </SlideImageLeft>
        )
      },
    },
    {
      content: (props) => {
        props.action('pause')
        return (
          // <SlideImageRight imageName={'Derna_s-smile-is-a-homeland'} objectFit={'cover'}>
          <SlideImageRight imageName={'Derna_s-smile-is-a-homeland'} objectFit={'contain'}>
            <SlideContent>
              <InvertedParagraph>
                I added a vintage picture of two Libyan men: one is buying stuff from the other, and the flowers of jasmine are there too. Derna is also known for its apple orchards, so I included a basket of apples on an old chair. There’s a note in Arabic that says: ‘Here is love and here is Derna’. It’s a reminder that Derna’s disaster is the reason Libyans united [to bring aid across political divides].
              </InvertedParagraph>
              <InvertedParagraph>
                Another heartfelt message in Arabic says: ‘Derna’s smile is a homeland’. I’m trying to lift up the spirits of so many Libyans here, as a reminder that Derna is not dead. We lost loved ones, but Derna is still there. It’s up to us now to rebuild the city.
              </InvertedParagraph>
              <InvertedParagraph>
                The street sign, ‘New Life’, is a message of hope I’m trying to send out to myself and others. Even though the disaster is greater and way bigger than us, that does not mean that we cannot start again. We will start again; we will bring back Derna.”
              </InvertedParagraph>
            </SlideContent>
          </SlideImageRight>
        )
      },
    },
  ]

  const StoriesArray = isMobile ? StoriesMobile : StoriesDesktop

  return (
    <Layout
      shareProps={{
        title: 'The New Humanitarian | Art in a time of crisis: Drawing Derna',
        url: 'https://www.thenewhumanitarian.org/special-report/2023/11/28/art-time-crisis-drawing-derna',
        socialTitle: 'Art in a time of crisis: Drawing Derna',
        socialDescription: "From Libya’s floods, artists show despair, remembrance, and hope.",
        socialImage: 'https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/2023-11/Derna-as-i-wept-over-it.jpeg.webp?itok=WQtGiwfb',
        nodeId: 263124,
        updatedTime: '2023-11-28T13:26:50+00:00',
        modiefiedTime: '2023-11-28T13:26:50+00:00',
        publishedTime: '2023-11-28T13:26:50+00:00',
      }}
    >

      <div id='start' className={'libya-art-wrapper'}>
        <div
          className={
            'relative max-w-full h-screen-ios h-screen-mobile sm:h-screen-desktop sm:max-h-screen-desktop overflow-hidden flex items-center justify-center bg-zinc-900'
          }
          id={'stories'}
        >
          <Stories
            stories={StoriesArray}
            loop
            isPaused={isPaused}
            width={'100%'}
            height={'100%'}
            storyContainerStyles={{
              background: '#282828',
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
            storyStyles={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            currentIndex={currentSlide}
          />
          <div
            className={
              'absolute flex flex-row justify-between items-center bottom-0 left-0 right-0 w-full h-20 sm:h-24 bg-zinc-900 bg-opacity-50 sm:bg-gradient-to-r sm:from-gray-900 sm:to-gray-900 sm:via-transparent sm:bg-opacity:30 text-white px-3 z-50'
            }
            style={{
              zIndex: 999,
            }}
          >
            <div className={'h-3 absolute -top-3 left-0 bg-zinc-200 inline w-full z-30 bg-opacity-80'} />
            <motion.div
              animate={{
                width: currentSlide < 1 ? `2.5%` : `${(currentSlide / (StoriesArray.length - 1)) * 100}%`,
              }}
              transition={{
                duration: 0.15,
                ease: 'easeIn',
              }}
              className={'h-3 absolute -top-3 left-0 bg-burgundy inline w-full z-50'}
              style={{
                width: `${((currentSlide + 1) / StoriesArray.length) * 100}%`,
              }}
            />
            <button
              className={`px-3 py-1 font-bold cursor-pointer ${currentSlide === 0 ? 'opacity-30 pointer-events-none' : ''}`}
              onClick={() => changeSlide('back')}
            >
              ← Back
            </button>
            {IMAGE_TITLES_DESKTOP[currentSlide] && (
              <div
                className={`justiy-center gap-3 items-center hidden sm:flex flex-row px-4 py-3 font-bold border ${!isPaused ? 'bg-transparent' : 'bg-zinc-50 text-zinc-900'} ${IMAGE_TITLES_DESKTOP[currentSlide].includes('About') ? 'rounded-full bg-zinc-600' : ''}`}
              >
                {!IMAGE_TITLES_DESKTOP[currentSlide].includes('About') &&
                  (<span className={'w-8 h-8 pb-1'}>
                    <svg width="100%" height="auto" viewBox="0 0 134 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M133.333 41.6665C133.333 37.0623 129.604 33.3331 125 33.3331H8.33333C3.7292 33.3331 0 37.0623 0 41.6665V125C0 129.604 3.7292 133.333 8.33333 133.333H125C129.604 133.333 133.333 129.604 133.333 125V41.6665ZM16.6667 116.667V91.6666L41.6667 66.6665L91.6667 116.667H16.6667ZM95.8333 79.1666C88.9323 79.1666 83.3333 73.5676 83.3333 66.6665C83.3333 59.7655 88.9323 54.1665 95.8333 54.1665C102.734 54.1665 108.333 59.7655 108.333 66.6665C108.333 73.5676 102.734 79.1666 95.8333 79.1666ZM43.9947 24.9998L58.724 10.2864C58.5626 9.65101 58.3334 9.03121 58.3334 8.33335C58.3334 3.72921 62.0626 0 66.6667 0C71.2708 0 75 3.72921 75 8.33335C75 9.00002 74.7709 9.57294 74.625 10.1875L89.4371 24.9995H77.5516L68.7912 16.2391C68.0985 16.4214 67.4162 16.6662 66.6661 16.6662C65.9317 16.6662 65.2806 16.437 64.614 16.2599L55.8744 24.9995L43.9947 24.9998Z" fill="black" />
                    </svg>
                  </span>)
                }
                <span className={`${IMAGE_TITLES_DESKTOP[currentSlide].includes('About') ? 'text-white' : ''}`}>
                  {IMAGE_TITLES_DESKTOP[currentSlide]}
                </span>
              </div>
            )}
            {IMAGE_TITLES_MOBILE[currentSlide] && (
              <div
                className={`justiy-center items-center gap-2 flex sm:hidden flex-row p-2 px-3 font-bold border text-center opacity-80 ${!isPaused ? 'bg-transparent' : 'bg-zinc-50 text-zinc-900'} ${IMAGE_TITLES_MOBILE[currentSlide].includes('About') ? 'rounded-full bg-zinc-600 px-4' : ''}`}
                style={{
                  maxWidth: '180px',
                }}
              >
                {!IMAGE_TITLES_MOBILE[currentSlide].includes('About') &&
                  (<span className={'w-8 h-8 pb-1'}>
                    <svg width="100%" height="auto" viewBox="0 0 134 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M133.333 41.6665C133.333 37.0623 129.604 33.3331 125 33.3331H8.33333C3.7292 33.3331 0 37.0623 0 41.6665V125C0 129.604 3.7292 133.333 8.33333 133.333H125C129.604 133.333 133.333 129.604 133.333 125V41.6665ZM16.6667 116.667V91.6666L41.6667 66.6665L91.6667 116.667H16.6667ZM95.8333 79.1666C88.9323 79.1666 83.3333 73.5676 83.3333 66.6665C83.3333 59.7655 88.9323 54.1665 95.8333 54.1665C102.734 54.1665 108.333 59.7655 108.333 66.6665C108.333 73.5676 102.734 79.1666 95.8333 79.1666ZM43.9947 24.9998L58.724 10.2864C58.5626 9.65101 58.3334 9.03121 58.3334 8.33335C58.3334 3.72921 62.0626 0 66.6667 0C71.2708 0 75 3.72921 75 8.33335C75 9.00002 74.7709 9.57294 74.625 10.1875L89.4371 24.9995H77.5516L68.7912 16.2391C68.0985 16.4214 67.4162 16.6662 66.6661 16.6662C65.9317 16.6662 65.2806 16.437 64.614 16.2599L55.8744 24.9995L43.9947 24.9998Z" fill="black" />
                    </svg>
                  </span>)
                }
                <span className={`${IMAGE_TITLES_MOBILE[currentSlide].includes('About') ? 'text-white' : ''}`}>
                  {IMAGE_TITLES_MOBILE[currentSlide]}
                </span>
              </div>
            )}
            <button
              className={`px-3 py-1 font-bold cursor-pointer ${currentSlide >= StoriesArray.length ? 'opacity-30 pointer-events-none' : ''} ${currentSlide === 0 ? 'animate-pulse' : ''
                }`}
              onClick={() => changeSlide('next')}
            >
              {currentSlide + 1 >= StoriesArray.length && 'Restart →'}
              {currentSlide > 0 && currentSlide + 1 < StoriesArray.length && 'Next →'}
              {currentSlide === 0 && 'Start →'}
            </button>
          </div>
          <div
            className={`absolute top-0 left-0 w-full h-full flex flex-row sm:grid sm:grid-cols-2 cursor-pointer ${currentSlide + 1 >= StoriesArray.length ? 'pointer-events-none' : ''
              }`}
            style={{ zIndex: 999 }}
          >
            <div
              className={`w-full h-full ${currentSlide === 0 ? 'opacity-30 pointer-events-none hidden' : ''} ${currentSlide + 1 >= StoriesArray.length ? 'hidden' : ''
                }`}
              onClick={() => {
                changeSlide('back')
              }}
            />
            <div
              className={`w-full h-full ${currentSlide === 0 ? 'sm:ml-full' : ''} ${currentSlide + 1 >= StoriesArray.length ? 'hidden' : ''}`}
              onClick={() => {
                changeSlide('next')
              }}
            />
          </div>
        </div>

        <div className={'bg-zinc-900 w-full py-8 sm:py-24 relative flex flex-col justify-center items-center z-50'}>
          <div className={'px-8 sm:px-0 text-left inverted flow max-w-6xl'}>
            <ArticleTags />
            <p className={'pt-4 text-2xl font-bold sm:text-4xl sm:pt-8'}>About the author</p>
            <br />
            <AuthorInfoOld
              name={'Zainab Chamoun'}
              role={'Lebanese freelance journalist and researcher whose work focuses on community-led development, religion, and politics.'}
              imageUrl={'https://assets.thenewhumanitarian.org/s3fs-public/styles/author/public/zainab-chamoun_0.jpg.webp?itok=6531iJR6'}
              twitter={'ChamounZainab'}
            />
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default LibyaArtPiece

export const query = graphql`
	query LibyaArtPiece {
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg"] }, relativePath: { glob: "stories/2023/libya-art-piece/*" } }
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(width: 1280, quality: 85, placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`
